import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "khaled-admin";
export const domainName = "khaled-sakr.com";

export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = true;
export const isCodes = true;
export const isBtns = true;
export const isLoginTokens = true;
export const profileStatistics = true;

export const isFreeTrialAvailable = false;
export const enableAutoLogin = false;

export const adminPanelColor = "cyan";
export const headerSectionColor = "cyan";
export const navbarIconColor = "text-cyan-500";
export const navbarDisclosureColor = "cyan";

export const progressBarColor = "waterMelon";
export const loadingBarColor = "cyan";

export const isBunny = false;

export const isAdminAbleToChangeUsersYear = true;

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = true;
export const prepaidInfoColor = "cyan";

export const autoChangeRandomNames = false;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "yellow";
export const registerPageColor = "rose";

export const isAnalytics = false;

export const isMultiAdmin = true;

export const showExamStatisticsTable = true;
export const showVideoStatisticsTable = true;

export const isSubjectSubscriptionable = false;

export const isCommunity = false;

export const isGlobalNotifications = true;

export const isAdminUserStatistics = true;

export const isChangeUserData = true;

export const isSendSmsMessages = true;

export const isRemotePlatformIntegrationEnabled = true;
export const isAskSakr = true;

export const isFinishedUnfinishedExamButton = true;

export const isCoursesRenamed = false;

export const isSaveExamLocalStorage = true;

export const isYoutubeVideoButton = false;

export const isManuallyDisablingAllVideos = false;
export const isManuallyDisablingAllExams = false;
export const isManuallyDisablingTempNotifications = false;
export const isManuallyDisablingUserAnalytics = false;
export const isManullayDisablingCenterExamResultToken = false;
export const isManullayDisablingPrepaidStore = false;
export const isManullayDisablingUnassignedSubscriptions = false;
export const isManullayDisablingNotifications = true;
export const isManuallyDisablingGlobalNotifications = false;
export const isManuallyDisablingFetchUserData = false;
