import React, { useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import BooleanIndicator from "../../components/ui/BooleanIndicator";
import RemoteTable from "../../components/ui/RemoteTable";
import { printUnit } from "../../utils/ar";
import Button from "../../components/ui/Button";
import { handleInputChange } from "../../services/formServices";
import InputField from "../../components/form/elements/InputField";

const CouponsTable = () => {
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "الكوبون",
            reorder: true,
            selector: (row) => row.name,
            sortable: true,
            sortField: "name",
        },
        {
            name: "مُفعل",
            reorder: true,
            selector: (row) => <BooleanIndicator value={row.is_active} />,
            sortable: true,
            sortField: "is_active",
        },
        {
            name: "مرات الاستخدام",
            reorder: true,
            selector: (row) => printUnit(row.total_used_coupons, "مرة"),
            sortable: true,
            sortField: "total_used_coupons",
        },
        {
            name: "مرات الاستخدام المتبقية",
            reorder: true,
            selector: (row) => printUnit(row.total_limit - row.total_used_coupons, "مرة"),
            sortable: true,
            sortField: "total_used_coupons",
        },
        {
            name: "مُفعل",
            reorder: true,
            selector: (row) => <BooleanIndicator value={row.is_active} />,
            sortable: true,
            sortField: "is_active",
        },
        {
            name: "مرات الاستخدام الكوبون الاجمالية ",
            reorder: true,
            selector: (row) => printUnit(row.total_limit, "مرة"),
            sortable: true,
            sortField: "total_limit",
        },
        {
            name: "مرات الاستخدام للمستخدم",
            reorder: true,
            selector: (row) => printUnit(row.limit_per_user, "مرة"),
            sortable: true,
            sortField: "limit_per_user",
        },
        {
            name: "الخصم",
            reorder: true,
            selector: (row) => row.discount,
            sortable: true,
            sortField: "discount",
        },
        {
            name: "اقصى مبلغ للخصم",
            reorder: true,
            selector: (row) => row.maximum,
            sortable: true,
            sortField: "maximum",
        },
    ];

    const initialState = {
        name: "",
    };

    const [filterData, setFilterData] = useState(initialState);
    const [data, setData] = useState(initialState);

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="name"
                        placeholder="اكتب أسم الكوبون ؟"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/coupons/paginate`}
                    columns={columns}
                    filterData={filterData}
                />
            </div>
        </AdminContainer>
    );
};

export default CouponsTable;
